import React, { useEffect, useState, useCallback } from 'react';
import showdown from 'showdown';
import { useHistory } from 'react-router-dom';
import { getAlerts } from '../api/strapiService';

const converter = new showdown.Converter(); 

const colorMap = {
  'Tangerina': '#FFBB00',
  'Amarelo Ipiranga': '#FFD100',
  'Azul Ipiranga': '#0045B5',
  'Branco': '#FFFFFF',
  'Vermelho': '#FF5E43',
  'Rosa': '#DD84C2',
  'Turquesa': '#78E6E9',
  'Marinho': '#00266E'
};

const getTextColor = (backgroundColor) => {
  switch (backgroundColor) {
    case colorMap['Amarelo Ipiranga']:
    case colorMap['Tangerina']:
    case colorMap['Branco']:
      return '#0b3d91';
    case colorMap['Azul Ipiranga']:
      return '#FFD100';
    default:
      return '#fff';
  }
};

const MessageBanner = () => {
  const [messages, setMessages] = useState([]);
  const [error, setError] = useState(null); 
  const history = useHistory();

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const { data } = await getAlerts();
        const now = new Date();
        const activeMessages = data.filter(
          message => new Date(message.startDate) <= now && new Date(message.endDate) >= now
        );
        setMessages(activeMessages);
      } catch (error) {
        console.error('Error fetching messages:', error);
        setError('Failed to load messages.');
      }
    };

    fetchMessages();
  }, []);

  const createMarkup = (markdownContent) => {
    const htmlContent = converter.makeHtml(markdownContent);
    return { __html: htmlContent };
  };

  const handleLinkClick = useCallback((event) => {
    const url = event.target.getAttribute('href');
    if (url.startsWith('/')) {
      event.preventDefault();
      history.push(url);
    }
  }, [history]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="alert-container">
      {messages.map((message, index) => {
        const backgroundColor = colorMap[message.color.trim()];
        const textColor = getTextColor(backgroundColor);

        return (
          <div
            key={index}
            className="alert"
            style={{
              backgroundColor,
              color: textColor,
              borderColor: backgroundColor,
              fontWeight: 'bold'
            }}
            dangerouslySetInnerHTML={createMarkup(message.content)}
            onClick={handleLinkClick}
          />
        );
      })}
    </div>
  );
};

export default MessageBanner;
